import React, { Component } from "react";
import Header from "./Header";
import UploadFileForLabel from "./UploadFileForLabel";
import Loader from "./Loader";
import ShowingCompareResult from "./ShowingComapareResult";

class CompareCoaAndNihProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: [],
      openResultModal: false,
      value: null,
      openUploadFile: false,
    };
    this.updateList = this.updateList.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const data = await this.props.compareCoaAndNihProductList();
    this.setState({ loading: false, list: data.list });
  }

  updateList(newList) {
    this.setState({ list: newList });
  }

  render() {
    return (
      <div>
        <Header menuSelected="compareproductlabel" {...this.props} />
        <main className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="my-3 p-3 bg-white rounded shadow-sm"
                style={{ minHeight: "180px", fontSize: "14px" }}
              >
                <h3 className="border-bottom border-gray pb-2 mb-0">
                  <span
                    style={{ marginRight: "10px" }}
                    className="fa-stack fa-md"
                  >
                    <i className="fa fa-circle-thin fa-stack-2x"></i>
                    <i className="fa fa-list fa-stack-1x"></i>
                  </span>
                  Compare COA and NIH product
                  <button
                    type="button"
                    class="btn btn-primary pull-right"
                    onClick={() => this.setState({ openUploadFile: true })}
                  >
                    Upload document
                  </button>
                </h3>
                <br />
                {this.state.loading && <Loader />}
                <table
                  className="table table-striped"
                  style={{ tableLayout: "fixed" }}
                >
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "top",
                          width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        COA Document
                      </th>
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "top",
                          width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        NIH Document
                      </th>
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "top",
                          width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Result
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(this.state.list || []).map((data) => (
                      <tr>
                        <td
                          style={{
                            verticalAlign: "middle",
                            fontSize: "12px",
                          }}
                        >
                          <a href={data?.coa_product_url} target="_blank">
                            {data?.coa_product_name}
                          </a>
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            fontSize: "12px",
                          }}
                        >
                          <a href={data?.nih_product_url} target="_blank">
                            {data?.nih_product_name}
                          </a>
                        </td>
                        <td>
                          <i
                            onClick={() =>
                              this.setState({
                                openResultModal: true,
                                value: data?.result,
                              })
                            }
                            style={{ fontSize: "15px", cursor: "pointer" }}
                            class="fa fa-eye"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
        <UploadFileForLabel
          show={this.state.openUploadFile}
          onClose={() => this.setState({ openUploadFile: false })}
          updateList={this.updateList}
        />
        <ShowingCompareResult
          show={this.state.openResultModal}
          value={this.state.value}
          onClose={() => this.setState({ openResultModal: false, value: null })}
        />
      </div>
    );
  }
}

export default CompareCoaAndNihProduct;
